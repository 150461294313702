@mixin mod-experience-video-presentazione() {
    .experience-video-presentazione, .experience-video-appuntamento, .experience-video-agenzia{
        margin-top: -80px;
        .video-container{
            position: absolute;
            overflow-y: hidden;
            overflow-x: hidden;
            top: 0;
            left: 0;
            width: 100vw;
            // height: 100vh;
            height: 100%;
            video{
                display: block;
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                z-index: 1;
            }
        }

        // .icon-volume{
        //         z-index: 20; 
        //         position: fixed; 
        //         right: 30px;
        //         bottom: 30px;
        //         cursor: pointer;
        //     img{
        //         height: 40px;
        //         width: 40px;
        //     }
        // }

        .video-presentazione{
                
            position: fixed;
            right: 0;
            bottom: 0;
            min-width: 100%;
            min-height: 100%;

        }
        .mask{
            display: none;
        }
        .button-container{
            z-index: 50;
            right: 30px;
            display: flex;
            // position: relative;
            position: absolute;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            text-align: center;
            // min-height: 100vh;
            height: 100%;
            float: right;

            &-title{
                font-size: 32px;
                font-weight: bold;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.14;
                letter-spacing: normal;
                color: $white;
                margin-bottom: 30px;
                opacity: 0;
            }
            
            .alleanza-button{
                width: 318px;
                height: 50px;
                min-height: 50px;    
                font-size: 18px;
                text-transform: none;
                font-weight: bold;
                text-align: center;
                padding-top: 5px;
                padding-left: 0px;
                padding-right: 0px;
                margin-bottom: 20px;
                opacity: 0;
            }

        }
    }

    // Animazione glow per mute/unmute
    @-webkit-keyframes glow { 
        0%, 19%{
            opacity: 0;
        }
        20% { 
            opacity: 1;
            background:linear-gradient(-45deg, transparent, transparent, transparent, transparent, rgba(255,255,255,0.51));
        }
        23% { 
            opacity: 1;
            background:linear-gradient(-45deg, transparent, transparent, transparent, rgba(255,255,255,0.51), transparent);
        }
        26% { 
            opacity: 1;
            background:linear-gradient(-45deg, transparent, transparent, rgba(255,255,255,0.51), transparent, transparent);
        }
        29% { 
            opacity: 1;
            background:linear-gradient(-45deg, transparent, rgba(255,255,255,0.51), transparent, transparent, transparent);
        }
        31% {
            opacity: 1;
            background: linear-gradient(-45deg, rgba(255,255,255,0.51), transparent, transparent, transparent, transparent);
        } 
        
        32%, 100%{
            opacity: 0;
        }
    }

    @keyframes glow { 
        0%, 19%{
            opacity: 0;
        }
        20% { 
            opacity: 1;
            background:linear-gradient(-45deg, transparent, transparent, transparent, transparent, rgba(255,255,255,0.51));
        }
        24% { 
            opacity: 1;
            background:linear-gradient(-45deg, transparent, transparent, transparent, rgba(255,255,255,0.51), transparent);
        }
        28% { 
            opacity: 1;
            background:linear-gradient(-45deg, transparent, transparent, rgba(255,255,255,0.51), transparent, transparent);
        }
        32% { 
            opacity: 1;
            background:linear-gradient(-45deg, transparent, rgba(255,255,255,0.51), transparent, transparent, transparent);
        }
        36% {
            opacity: 1;
            background: linear-gradient(-45deg, rgba(255,255,255,0.51), transparent, transparent, transparent, transparent);
        } 
        
        36%, 100%{
            opacity: 0;
        }
    }

    .toggle-volume {
        z-index: 60; 
        position: absolute; 
        right: 30px;
        bottom: 30px;
        cursor: pointer;
        input {
            position: absolute;
            opacity: 0;
            &+ label {
                position: relative;
                height: 40px;
                margin: 0;
                width: 107px;
                cursor: pointer;
                .toggle-volume-bg {
                    position: absolute;
                    width: 92px;
                    top: 0;
                    left: 0;
                    height: 40px;
                    opacity: 0.5;
                    border-radius: 100px;
                    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
                    background-color: $color-alleanza-orange;
                    content: '';
                    transition: width 0.5s;
                }
                &::before{
                    content: '';
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 40px;
                    height: 40px;
                    border-radius: 50%;
                    z-index: 1;
                    opacity: 1;
                    transition: opacity 2s ease;
                    
                    -webkit-animation: glow ease 3s infinite; 
                    animation: glow ease 3s infinite;
                    -webkit-animation-delay: 1s; 
                    animation-delay: 1s;
                }
                &::after {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 40px;
                    height: 40px;
                    border-radius: 50%;
                    background-size: 40px;
                    transition: left 0.5s ease, background-image 0.5s ease;
                    content: '';
                    background-image: url("../images/img/ex-audio-active.svg");
                }
                p{
                    font-size: 12px;
                    font-weight: bold;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: normal;
                    letter-spacing: normal;
                }
                .label-active{
                    padding-left: 46px;
                    // display: block;
                    padding-top: 12px;
                    visibility: visible;
                    opacity: 1;
                    position: relative;
                    transition: visibility 0s, opacity 0.6s linear;
                    text-shadow: 0 2px 8px rgba(0, 0, 0, 0.65);
                }
                .label-disabled{
                    // display: none;
                    visibility: hidden;
                    opacity: 0;
                    position: absolute;
                }
            }
            
            &:checked + label {
                .toggle-volume-bg{
                    width: 107px;
                    background-color: $white;
                }
                &::before{
                    opacity: 0 !important;
                    transition: opacity 0s ease;
                }
                &::after {
                    left: 67px;
                    background-image: url("../images/img/ex-audio-disabled.svg");
                }
                .label-disabled{
                    padding-left: 10px;
                    padding-top: 12px;
                    // display: block;
                    visibility: visible;
                    position: relative;
                    opacity: 1;
                    transition: visibility 0s, opacity 0.6s linear;
                    text-shadow: 0 2px 8px rgba(0, 0, 0, 0.65);
                }
                .label-active{
                    // display: none;
                    visibility: hidden;
                    opacity: 0;
                    position: absolute;
                }
            }
            
            &[disabled] {
                &+ label {
                    cursor: default;
                    
                    .toggle-volume-bg {
                        border-color: $light-grey-2;
                        background-color: $light-grey-2;
                    }
                    &::after {
                        background-color: $light-grey-1;
                    }
                }
            }
        }
        
        &:focus {
            outline: 0;
            
            // input + label {
            //     .toggle-volume-bg {
            //         border: 2px solid $color-alleanza-orange;            
            //     }
            // }
        }
    }
}
@media screen and (max-aspect-ratio: 1920/1080) {
    .video-container > video {
      height: 100%;
    }
}
@media screen and (min-aspect-ratio: 1920/1080) {
    .video-container > video {
        height: 100%;
    }
}

@mixin mod-experience-video-presentazione-desktop(){
    .experience-video-presentazione, .experience-video-appuntamento, .experience-video-agenzia{
        .button-container{
            right: 118px;
            &-title{
                width: 330px;
                font-size: 42px;
            }
            .alleanza-button{
                width: 350px;
            }
        }

        // .icon-volume{
        //     bottom: 45px; 
        //     right: 120px; 
        // }
        
        .mask{
            display: none;
        }
    }

    .toggle-volume {
        right: 68px;
        bottom: 45px;
    }
}

@mixin mod-experience-video-presentazione-tablet-portrait() {
    .experience-video-presentazione, .experience-video-appuntamento, .experience-video-agenzia{
        .button-container{
            right: 0;
            float: unset;
            width: 100%;
        }

        .mask{
            background-image: linear-gradient($color-alleanza-blue, $color-alleanza-blue);
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 10;
            opacity: 0.8;
            display: block;
        }

        // .icon-volume{
        //     img{
        //         height: 40px;
        //         width: 40px;
        //     }
        // }
    }

    .toggle-volume {
        right: 30px;
        bottom: 29px;
        input {
            &+ label {
                height: 40px;
                margin: 0;
                width: 107px;
                cursor: pointer;
                .toggle-volume-bg {
                    position: absolute;
                    width: 92px;
                    height: 40px;
                }
                &::before{
                    content: '';
                    width: 40px;
                    height: 40px;
                }
                &::after {
                    width: 40px;
                    height: 40px;
                    background-size: 40px;
                }
                p{
                    font-size: 12px;
                }
                .label-active{
                    position: relative;
                    padding-left: 46px;
                    display: block;
                    padding-top: 12px;
                }
            }
            
            &:checked + label {
                .toggle-volume-bg{
                    width: 107px;
                }
                &::after {
                    left: 67px;
                }
                .label-disabled{
                    padding-left: 10px;
                    padding-top: 12px;
                }
            }
        }
    }
}

@mixin mod-experience-video-presentazione-mobile() {
    .experience-video-presentazione, .experience-video-appuntamento, .experience-video-agenzia{
        .button-container{
            float: unset;
            padding: 0 15px 0 15px;
            right: 0;
            width: 100%;
            // justify-content: flex-end;
            &-title{
                font-size: 28px;
                margin-top: auto;
            }
            
            .alleanza-button{
                max-width: 344px;
                width: 100%;
                font-size: 16px;
            }
        }

        // .icon-volume{
        //     bottom: 16px; 
        //     right: 16px; 
        //     img{
        //         height: 40px;
        //         width: 40px;
        //     }
        // }

        .mask{
            background-image: linear-gradient($color-alleanza-blue, $color-alleanza-blue);
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 10;
            opacity: 0.8;
            display: block;
        }
    }

    .toggle-volume {
        right: 16px;
        bottom: 16px;
        input {
            &+ label {
                height: 40px;
                margin: 0;
                width: 109px;
                cursor: pointer;
                .toggle-volume-bg {
                    position: absolute;
                    width: 92px;
                    height: 40px;
                }
                &::before{
                    content: '';
                    width: 40px;
                    height: 40px;
                }
                &::after {
                    width: 40px;
                    height: 40px;
                    background-size: 40px;
                }
                p{
                    font-size: 12px;
                }
                .label-active{
                    position: relative;
                    padding-left: 46px;
                    display: block;
                    padding-top: 12px;
                }
            }
            
            &:checked + label {
                .toggle-volume-bg{
                    width: 109px;
                }
                &::after {
                    left: 69px;
                }
                .label-disabled{
                    padding-left: 10px;
                    padding-top: 12px;
                }
            }
        }
    }
}
  