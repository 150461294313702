@mixin mod-vertical-accordion() {
    .vertical-accordion {

        position: relative;
        color: $white;
        // margin-bottom: 50px;

        /* width */
        ::-webkit-scrollbar {
            // width: 20px;
            display: none;
        }
        
        picture.side-picture {
            display: none;
            position: absolute;
            bottom: -170px;
            left: 0;
            z-index: 10;
            
            img {
                width: 450px;
                height: 450px;
            }
        }
        
        ul.va-ul {
            padding: 0;
            margin: 0;
            overflow: hidden;
            
            li.va-li {
                list-style-type: none;
                
                .va-header {
                    padding: 15px;
                    cursor: pointer;
                    
                    small, h4 {
                        font-size: 18px;
                        line-height: 1.33;
                        font-weight: 700;
                        text-transform: uppercase;
                    }

                    *::-ms-backdrop, small, h4 {
                        position: fixed
                    }
                }
                
                .va-content {
                    display: none;
                    
                    h2 {
                        font-size: 28px;
                        line-height: 1.21;
                        font-weight: 700;
                        margin-bottom: 20px;
                    }
                    
                    h3 {
                        font-size: 24px;
                        line-height: 1.25;
                        font-weight: 700;
                        margin-bottom: 20px;
                    }
                    
                    small, h4 {
                        display: none;
                        font-size: 24px;
                        line-height: 1.25;
                        font-weight: 700;
                        text-transform: uppercase;
                        margin-bottom: 10px;
                    }
                    
                    p {
                        font-size: 18px;
                        line-height: 1.33;
                        margin-bottom: 20px;
                    }
                    
                    img {
                        width: 100%;
                        border-radius: 8px;
                        margin-bottom: 20px;
                    }
                    
                    ul {
                        padding-left: 20px;
                        margin-bottom: 20px;
                        
                        li {
                            list-style-type: disc;
                            
                            p {
                                font-size: 16px;
                                line-height: 1.38;
                                margin: 0;
                            }
                        }
                    }
                    
                    .two-columns {
                        display: flex;
                        justify-content: space-between;
                        margin-top: 65px;
                        
                        .column {
                            width: calc(50% - 24px);
                            order: 1;
                            
                            h5 {
                                font-size: 20px;
                                line-height: 1.3;
                                font-weight: 700;
                                margin-bottom: 10px;
                            }
                            
                            p {
                                font-size: 16px;
                                line-height: 1.38;
                                margin: 0;
                            }
                        }
                    }
                    .player-wrap-youtube{
                        // width: 532px;
                        width: 100%;
                        height: 248px;
                        .youtubePlayer{
                            border-radius: 8px;
                        }
                    }

                    .player-img{
                        width: 100%;
                        height: 248px;
                        border-radius: 8px;
                        object-fit: cover;
                        &:hover{
                            cursor: pointer;
                        }
                    }
                    
                    .va-video-aspect-ratio {
                        position: relative;
                        width: 100%;
                        height: 0px;
                        padding-top: 56.25%; /* Aspect-Ratio 16:9 */
                        margin-top: 40px;
                        
                        .va-video {
                            position: absolute;
                            width: 100%;
                            height: 100%;
                            top: 0;
                            left: 0;
                            border: none;
                            border-radius: 8px;
                        }
                    }
                    
                    .va-hidden-content {
                        // max-height: 94px;
                        max-height: 0;
                        overflow: hidden;
                        margin-bottom: 40px;
                        transition: max-height 0.6s ease;
                    }
                    
                    .va-toggle-hidden-content {
                        span {
                            display: inline-block;
                            font-size: 16px;
                            line-height: 1.38;
                            font-weight: 700;
                            text-decoration: underline;
                            cursor: pointer;
                            
                            &.show-less {
                                display: none;
                            }
                        }
                    }
                }
                .va-content-wrapper {
                    height: 100% ;
                    padding: 20px 25px 130px 120px;
                    &.set-scrollbar{
                        overflow-y: scroll;
                        -ms-overflow-style: none;  /* Internet Explorer 10+ */
                        scrollbar-width: none; // firefox
                    }

                    &.va-video-wrapper {
                        padding-bottom: 30px;
                    }
                    

                    &.first-accordion{
                        width: 100% !important;
                        padding-left: 110px;
                        padding-right: 24px;
                    }
                }
                
                &.active {
                    .va-content {
                        display: block;
                    }
                }
                
                &.show-all {
                    .va-content {
                        .va-hidden-content{
                            max-height: none !important;
                        }
                        .va-toggle-hidden-content {
                            span{
                                
                                &.show-more {
                                    display: none;
                                }
                                
                                &.show-less {
                                    display: inline-block;
                                }
                            }
                        }
                    }
                }
                
                &:nth-child(1) {
                    order: 1;
                    background-color:$color-alleanza-blue;
                }
                
                &:nth-child(2) {
                    order: 2;
                    background-color: $dark-blue;
                }
                
                &:nth-child(3) {
                    order: 3;
                    background-color: $blue-medium;
                }
                
                &:nth-child(4) {
                    order: 4;
                    background-color: $blue;
                }
                
                &:nth-child(5) {
                    order: 5;
                    background-color: $blue-light;
                }
            }
        }

        &.animated{
            picture.side-picture {
                left: -10px;
            }
        }
    }


}

@mixin mod-vertical-accordion-mobile() {
    .vertical-accordion {
        margin-bottom: 0;
        .player-wrap-youtube{
            // width: 345px;
            height: 200px;
            object-fit: contain;
        }
        .player-img{
            height: 200px;
        }
        ul.va-ul {
            li.va-li {
                
                &.active{
                    // padding-top: 40px;
                }
                .va-header {
                    padding: 20px 90px;
                    height: 52px;

                    small, h4 {
                        position: absolute;
                        // top: 50%;
                        // left: 50%;
                        white-space: nowrap;
                        transform:  translateX(0%) translateY(-30%) rotate(0deg);
                        -ms-transform:  translateX(0%) translateY(-30%) rotate(0deg);
                        margin-left: -75px;
                    }
                }
            }

            .va-content-wrapper {
                // height: 460px !important;
                min-height: 460px;
                height: auto;
                padding: 0px 15px 46px 15px !important;
                &.first-accordion{
                    padding-left: 15px !important;
                    padding-right: 15px !important;
                }
            }

        }
    }
}

@mixin mod-vertical-accordion-tablet-portrait() {
    .vertical-accordion {
        margin-bottom: 0;
        .player-wrap-youtube{
            // width: 588px;
            height: 248px;
            object-fit: contain;
        }
        .player-img{
            height: 248px;
        }
        ul.va-ul {
            li.va-li {
                &.active{
                    // padding-top: 40px;
                }
                .va-header {
                    padding: 20px 90px 20px 90px;
                    height: 60px;
                    align-items: center;

                    small, h4 {
                        position: absolute;
                        // top: 50%;
                        // left: 50%;
                        // font-size: 24px;
                        // line-height: 1.25;
                        white-space: nowrap;
                        transform:  translateX(0%) translateY(-10%) rotate(0deg);
                        -ms-transform:  translateX(0%) translateY(-10%) rotate(0deg);
                    }
                }
                
                .va-content {

                    .two-columns {
                        margin-top: 30px;
                    }
                    
                    .va-hidden-content {
                        // max-height: 70px;
                        max-height: 0;
                    }
                }
                
                .va-content-wrapper {
                    height: 400px; // 440px;
                    padding: 0 90px 80px;

                    &.first-accordion{
                        padding-left: 90px !important;
                        padding-right: 90px !important;
                    }

                }
            }
        }
    }
}

@mixin mod-vertical-accordion-tablet-landscape() {
    .vertical-accordion {
        // min-height: 600px;
        height: 550px;
        margin-bottom: 50px;
        
        picture.side-picture {
            display: block;

            img {
                width: 450px;
                height: 450px;
                margin-left: -50%;
            }
        }
        
        ul.va-ul {
            display: flex;
            // min-height: 100vh;
            height: 550px;
            
            li.va-li {
                width: 80px;
                transition: width 0.3s ease, transform 0.3s ease;
                
                .va-header {
                    position: relative;
                    padding: 0;
                    width: 80px;
                    height: 100%;
                    opacity: 1;
                    z-index: 0;
                    transition: opacity 0.3s ease;
                    
                    .va-scrollable {
                        position: absolute;
                        top: 0;
                        width: 100%;
                        height: 100%;
                        transition: transform 0.3s ease;
                    }
                    
                    small, h4 {
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        font-size: 24px;
                        line-height: 1.25;
                        white-space: nowrap;
                        transform:  translateX(-50%) translateY(-50%) rotate(-90deg);
                        -ms-transform:  translateX(-55%) translateY(-50%) rotate(-90deg);
                    }
                }
                
                .va-content {
                    position: relative;
                    width: 100%; /*  */
                    height: 100%; /*  */
                    display: block;
                    overflow: hidden;
                    z-index: 1;
                    
                    h2 {
                        font-size: 42px;
                        line-height: 1.14;
                    }
                    
                    h3 {
                        font-size: 32px;
                        line-height: 1.19;
                    }
                    
                    small, h4 {
                        display: block;
                    }
                    
                    p {
                        font-size: 20px;
                        line-height: 1.3;
                    }
                    
                    .two-columns .column h5 {
                        font-size: 24px;
                        line-height: 1.25;
                    }
                    
                    .va-hidden-content {
                        // max-height: 80px; /* Show less - show more (add) */
                        max-height: 0;
                        // max-height: none; /* Show less - show more (delete) */
                    }
                    
                    .va-toggle-hidden-content {
                        // display: none; /* Show less - show more (delete) */
                        text-align: right; /* Show less - show more (add) */
                    }
                }
                
                .va-content-wrapper {
                    position: absolute;
                    width: calc(100vw - 340px);
                    padding: 60px 25px 130px 109px;
                    height: 100%;
                    // margin-top: 20px;
                    // padding: 40px 25px 130px 109px;
                    &.first-accordion{
                        padding-left: 110px !important;
                        padding-right: 24px !important;

                    }
                }
                
                &:hover:not(.active) {
                    transform: scale(1.1, 1);
                    
                    .va-scrollable {
                        transform: scale(0.9, 1);
                    }
                }
                
                &.active {
                    width: 100%;
                    transition: width 0.3s ease;
                    
                    .va-header {
                        position: absolute;
                        opacity: 0 !important;
                    }
                    
                    .va-content-wrapper { /* Show less - show more (delete all) */
                        // position: relative;
                        height: 100%;
                    }
                }
                
                &.show-all {
                    .va-content-wrapper {
                        position: relative;
                        height: 100%;
                    }
                }
            }
        }
    }
}

@mixin mod-vertical-accordion-desktop() {
    .vertical-accordion {
        margin-bottom: 50px;
        picture.side-picture {
            bottom: -250px;
            left: 0;
            img {
                // width: 195px;
                // height: 390px;

                width: 450px;
                height: 450px;
                margin-left: -50%;
            }
        }
        

        .player-wrap-youtube{
            // width: 690px;
            height: 280px;
            object-fit: contain;
        }
        .player-img{
            height: 280px;
        }
        
        ul.va-ul {
            height: 550px !important;
            li.va-li {
                width: 125px;
                
                .va-header {
                    width: 125px;
                }
                
                .va-content-wrapper {
                    width: auto;
                    -ms-transform: scale(0.9);
                    transform: scale(0.9);
                    -ms-transform-origin-y: -5px;
                    -ms-transform-origin-x: -5px;
                    -webkit-transform-origin-y: -5px;
                    -webkit-transform-origin-x: -5px;
                    // width: calc(100vw - 520px);  

                    &.first-accordion{
                        padding-left: 222px !important;
                        padding-right: 40px !important;
                        transform: none;
                    }
                }
            }
        }
    }
}

// Animation
@media(min-width: 990px) {
    .vertical-accordion.animated ul.va-ul li.va-li.is-hover {
        // width: 100px;
        width: 90px;
        transition: width 0.3s ease;
    }

    .vertical-accordion.animated ul.va-ul li.va-li.is-hover .va-header {
        // width: 100px;
        width: 90px;
        transition: width 0.3s ease;
    }

    .vertical-accordion.animated ul.va-ul li.va-li.is-not-hover:not(.active) {
        // width: 65px;
        width: 70px;
        transition: width 0.3s ease;
    }
    

    .vertical-accordion.animated ul.va-ul li.va-li.is-not-hover .va-header:not(.active) {
        // width: 65px;
        width: 70px;
        transition: width 0.3s ease;
    }
    
}

@media(min-width: 1440px) {
    .vertical-accordion.animated ul.va-ul li.va-li.is-hover {
        width: 155px
    }

    .vertical-accordion.animated ul.va-ul li.va-li.is-hover .va-header {
        width: 155px
    }

    .vertical-accordion.animated ul.va-ul li.va-li.is-not-hover:not(.active) {
        width: 110px
    }

    .vertical-accordion.animated ul.va-ul li.va-li.is-not-hover .va-header:not(.active) {
        width: 110px
    }
}